<template>
  <div class="home">
    Welcome
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  setup() {}
}
</script>

<style lang="less" scope>
.home {
  text-align: center;
}
</style>
