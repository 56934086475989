import { createApp } from 'vue';

import 'normalize.css';
import './utils/responsive';
import App from './App.vue';
import Icon from './components/Icon.vue';
import router from './router';
import store from './store';

createApp(App)
  .use(store)
  .use(router)
  .component('icon', Icon)
  .mount('#app');
