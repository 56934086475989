const doc = window.document;
const ele = doc.documentElement;

let tid = 0;

function refreshRem() {
  let width = ele.getBoundingClientRect().width;

  // 最大宽度
  if (width > 540) {
    width = 540;
  }

  const rem = (width / 375) * 16;
  ele.style.fontSize = rem + "px";
}

window.addEventListener(
  "resize",
  () => {
    clearTimeout(tid);
    tid = window.setTimeout(refreshRem, 300);
  },
  false
);

refreshRem();
